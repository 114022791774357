<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? `编辑库位 - ${form.number}` : "新增库位" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="formData"
          :rules="rules"
          :label-col="{ span: 4, md: 6 }"
          :wrapper-col="{ span: 16, md: 18 }"
        >
          <a-form-model-item prop="number" label="库位编号">
            <a-input v-model="formData.number" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="area" label="库区">
            <reservoir-area-select
              v-model="formData.area"
              :warehouse="formData.warehouse"
            ></reservoir-area-select>
          </a-form-model-item>
          <a-form-model-item prop="is_active" label="状态">
            <a-select v-model="formData.is_active" style="width: 100%">
              <a-select-option :value="true">激活</a-select-option>
              <a-select-option :value="false">冻结</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-textarea v-model="formData.remark" :rows="4" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { locationCreate, locationUpdate, locationNumber } from "@/api/data";
import rules from "./rules.js";

export default {
  components: {
    ReservoirAreaSelect: () => import("@/components/ReservoirAreaSelect/index"),
  },
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
      formData: {},
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.formData.id ? locationUpdate : locationCreate;

          func(this.formData)
            .then((data) => {
              this.$message.success(this.formData.id ? "修改成功" : "新增成功");
              this.$emit(this.formData.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        if (this.form.id) {
          this.formData = { ...this.form };
        } else {
          locationNumber().then((data) => {
            this.formData = {
              is_active: true,
              number: data.number,
            };
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
